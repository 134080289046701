import React, {useState} from 'react'
import Template from '../components/template';

import { ServerUrlAdmin, config, customerCode } from '../components/include';

import { Alerts } from '../components/notify';
import axios from 'axios'

const Cheque =()=> {
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});


	const [image, setImage] = useState('');
    const [users, setUsers] = useState([])
	const [isSubmitting, setIsSubmitting] = useState(false);
		
    //customerCode, accountNumber, taxPin, authorCode, taCode, InsuranceCode, frCode,
    var Initials = {
        code:Math.random().toString(36).substring(2,9),
		fullname:'',
		telephone:'',
        bankAccount:'',
        image:'',
        message:'',
        customer_code:customerCode

    }
	const [bank, setBank] = useState(Initials)
    
    const [errors, setErrors] = useState({});




    const handleChange = event =>{		
        let {name, value} = event.target;	
        setBank({...bank, [name] : value });
       setErrors({...errors, [name]:''});
    }

    const handleSubmit = event =>{
        event.preventDefault();
         

        window.scrollTo({top:0, left:0, behavior:'smooth'})
  
        let errors = {};  

        if(!bank.fullname){
            errors.fullname ='Please fill out this field';
        }
       
        if(!bank.telephone){
            errors.telephone ='Please fill out this field';
        }
        if(!bank.bankAccount){
            errors.bankAccount ='Please fill out this field';
        }
        if(!image){
            alert('Please upload image')
            errors.image ='Please fill out this field';
        }
       
        setErrors(errors)

        if(Object.keys(errors).length === 0){

        submit()
        }
        }

	
	

const  handleFile = function(fieldName){
    return function(newValue){                 
        const value = newValue.target.files[0]
      // setImage(value)
   readURL(newValue);
   }
   
  } 

   const readURL = (input)=>{       
    let displayMessage = '';
      var fileName =  document.getElementById('logo').value;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF"){
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 3) {
        displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 3 Mb';
       document.getElementById(input.target.name).value = '';
       setImage('')
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.
          var reader = new FileReader();//Initialize FileReader.
          reader.onload = function (e) {
                
              
          };
          reader.readAsDataURL(input.target.files[0]); 
          setImage(input.target.files[0])  
        }
    }
    }else{
        displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 3 Mb'
       document.getElementById(input.target.name).value = '';
       setImage('');
    }   
    
    if(displayMessage.length > 0){
    
        Alerts('Error!', 'danger', displayMessage)
      }
} 


 function submit(){  


   setNotice({...notice, 
                  isLoading: true})     
              
            let formData = bank;
            const fd = new FormData();
            for(let k in formData){
            fd.append(k, formData[k])
            }
            if(image!==''){
				fd.append("image", image);
			}
            let url = ServerUrlAdmin+'/save_controller.php?tablename=tbl_upload_check';
            axios.post(url, fd, config)
            .then(response =>{
            if(response.data.type === 'success'){

                alert(response.data.message)
                window.location.reload()
                      } else{
                 
                    window.scrollTo(0,0)
                    alert(JSON.stringify(response.data, null, 2))
                      }   
                  })
                  .catch((error)=>{
                      alert(error.message)
                  }).finally(()=>{
                    
                    setNotice({...notice, 
                      isLoading: false
                    })
                    setIsSubmitting(false);
                  }) 
              }

             



    return (<Template 
    title='Transfer Funds'>
    

    <div className="col-lg-7 col-md-7 col-xs-12">
<div className="inner-box my-resume">
        
        <div className="work-experence item">
                <h3>Deposit Cheque</h3>

                
                    <div className="row justify-content-center">
                    <div className="col-lg-9 col-md-12 col-xs-12">
                    <div className="add-resume box">
                        
                        
                                                                        
                        <br/><br/>
                        
                            
                            <form className="form-ad" action="transfer_presuccess1" method="POST">
                                
                                <h3 style={{color: '#26ae61', fontWeight:'bold'}}>Upload Cheque here</h3>
                                
                                <div className="form-group">
                                    <label className="control-label" style={{color: '#d51224', fontWeight:'bold'}}>Full Name</label>
                                    <input type="text" name="fullname" value={bank.fullname} onChange={handleChange} className="form-control" />

                                    <label  className="validation-invalid-label" >{ errors.fullname}</label>
                    </div>
                    
                    <div className="form-group">
                        
                        <label className="control-label" style={{color: '#d51224', fontWeight:'bold'}}>Phone Number </label>
                        <input type="text" name="telephone" value={bank.telephone} onChange={handleChange} className="form-control" />

<label className="validation-invalid-label" >{ errors.telephone}</label>
                    </div>
                    
                    <div className="form-group"><label className="control-label"></label>
                    
                    <label className="control-label" style={{color: '#d51224', fontWeight:'bold'}}>Account Number</label>
                    
                    <input type="text" name="bankAccount" value={bank.bankAccount} onChange={handleChange} className="form-control" />

<label className="validation-invalid-label" >{ errors.bankAccount}</label>
                    </div>
                    
                    <div className="form-group">
                        
                        <label className="control-label" style={{color: '#d51224', fontWeight:'bold'}}>Select Check</label>
                        <input id="logo" onChange={handleFile('logo')} accept="image/*"   name="logo" type="file" className="form-control" />

<label className="validation-invalid-label" >{ errors.image}</label>
                    </div>
                   
                   
                    
                    {notice.isLoading? <button type="button" className="submit"  disabled={true} ><span className='loader'></span> ... Please wait</button>: <input type="button" name="submitBtn" onClick={handleSubmit} className="btn btn-common" value="Confirm" />}	</form>
                    </div> 												
                    </div>
                    </div>
                    </div>
                                                        
                                </div>
                                                                    

                            </div>  

                                 
</Template>
);
       
}

export default React.memo(Cheque)  

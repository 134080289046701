
import Cookies from 'js-cookie'
import { mainToken } from './authentication'
var CryptoJS = require('crypto-js')
export const config = {
  headers: { 'content-type': 'multipart/form-data'}
} 


export const tok = process.env.REACT_APP_TOKEN;
export const secret = process.env.REACT_APP_SECRET;
export const sduser = process.env.REACT_APP_USER;
export const mail = process.env.REACT_APP_MAIL;
export const sdstf = process.env.REACT_APP_STAFF;


export const staffCode = Cookies.get(sdstf)?CryptoJS.AES.decrypt(Cookies.get(sdstf), secret).toString(CryptoJS.enc.Utf8):'null'

export const token = Cookies.get(tok)?CryptoJS.AES.decrypt(Cookies.get(tok), secret).toString(CryptoJS.enc.Utf8):'null'
export const customerCode = Cookies.get(sduser)?CryptoJS.AES.decrypt(Cookies.get(sduser), secret).toString(CryptoJS.enc.Utf8):'null'
export const email = Cookies.get(mail)?CryptoJS.AES.decrypt(Cookies.get(mail), secret).toString(CryptoJS.enc.Utf8):'null';



export  const ServerUrlAdmin = process.env.REACT_APP_ADMIN_SERVER;
  export  const ServerUrl = process.env.REACT_APP_CLIENT_SERVER;
export  const adminImages = process.env.REACT_APP_ADMIN_IMAGES;
export  const PictureUrl = process.env.REACT_APP_CLIENT_IMAGES;
export  const FrontEndUrl = process.env.REACT_APP_FRONTEND;
 
export const bankEmail = process.env.REACT_APP_BANKNAME;
export const bankName = process.env.REACT_APP_EMAIL;
import React, {useState, useEffect} from 'react'
import Template from '../components/template';

import { customerCode, PictureUrl, ServerUrl, config, adminImages } from '../components/include';


import { Alerts } from '../components/notify';
import axios from 'axios'
const LookUp =()=> {
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});

    const [users, setUsers] = useState([])
    const fetchContent =()=>{
    
        var sql ="Select * from tbl_customers where code ='"+customerCode+"'";
         
        const fd = new FormData();
        fd.append("sql", sql);
       // fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller.php'
        
        axios.post(url, fd, config).then(result=>{ 
            if(result.data.length!==0){
                setUsers(result.data[0])
            }
            
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
            
        })
    }
    
    
    useEffect(()=>{
        fetchContent() 
          },[]);

    return (<Template 
    title='Lookup Customer'>
    
{users.length!==0?
    <div className="col-lg-7 col-md-7 col-xs-12">
<div className="inner-box my-resume">
    <div className="author-resume">
            <div className="thumb">
            <img src={adminImages +'/customers/'+users.passport} onError={(e)=>{e.target.onerror = null; e.target.src=PictureUrl+"/"+users.gender+".png"}}alt="" />                                              </div>
                <div className="author-info">
                <h3 style={{color: '#d51224'}}>{users.fullname}</h3>
                    
                <p><span className="address"><i className="lni-map-marker"></i>{users.telephone}</span> <span><i className="ti-phone"></i>  {users.address} </span></p>
            </div>
    </div>

    <div className="work-experence item">
            <h3 style={{color: '#26ae61'}}>Lookup Customer</h3>

            
                <div className="row justify-content-center">
                <div className="col-lg-9 col-md-12 col-xs-12">
                <div className="add-resume box">

                <form className="form-ad" id="profileForm">
                <h3>Basic information</h3>
                <div className="form-group">
                <label className="control-label" style={{color: '#26ae61'}}>Name</label>
                <input type="text" className="form-control" placeholder="Name" id="name" readonly="" name="name" value={users.fullname} />
                </div>
                <div className="form-group">
                <label className="control-label"></label>
                <label className="control-label" style={{color: '#26ae61'}}>Email</label>
                <input type="text" className="form-control" placeholder="Your@domain.com" id="email" name="email" readonly="" value={users.email} />
                </div>
                <div className="form-group">
                <label className="control-label" style={{color: '#26ae61'}}>Date of Birth</label>
                <input type="date" className="form-control" placeholder="" readonly="" id="dob" name="dob" value={users.birthDate} />
                </div>
                <div className="form-group">
                <label className="control-label" s="" style={{color: '#26ae61'}} tyle={{color: '#26ae61'}}>Phone Number</label>
                <input type="text" className="form-control" placeholder="339686966" id="phone" readonly="" name="phone" value={users.telephone} />
                </div>
                <div className="form-group">
                <label className="control-label" style={{color: '#26ae61'}}>Address</label>
                <textarea className="form-control" rows="7" id="address" readonly="" value={users.address}name="address"> {users.address} </textarea>
                </div>
                <div className="form-group">
                <label className="control-label" style={{color: '#26ae61'}}> Name of Next of Kin</label>
                <input type="text" className="form-control" placeholder="next of kin" readonly="" id="nok" name="nok" value={users.nokin_name} />
                </div>
                <div className="form-group">
                <label className="control-label" style={{color: '#26ae61'}}> Next of Kin Address</label>
                <textarea className="form-control" rows="7" id="nokadd" readonly="" value={users.nokin_address} name="nokadd"> {users.nokin_address} </textarea>
                </div>
                <div className="form-group">
                <label className="control-label" style={{color: '#26ae61'}}>Next of Kin Relationship</label>
                <input type="text" className="form-control" placeholder="relationship" readonly="" id="nok_relationship" name="nok_relationship" value={users.nokin_relationship} />
                </div>
                <div className="form-group">
                <label className="control-label" style={{color: '#26ae61'}}>Next of Kin Phone Number</label>
                <input type="text" className="form-control" placeholder="" id="nokphone" readonly="" name="nokphone" value={users.nokin_phone} />
                </div>
              
                
                </form></div>
                    
                </div>
                
                
                </div>
                </div>
                </div>
                                                    
</div>:''}

       

</Template>
);
       
}

export default React.memo(LookUp)  

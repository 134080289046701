import React, {useEffect, useState} from 'react';
import {Alerts, SessionTimeout} from './components/notify'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';


import home from './main/home';
import transfer from './main/transfer';
import lookup_customer from './main/lookup_customer';
import transfer_local from './main/transfer_local';
import exchange from './main/exchange';
import previous from './main/previous';
import daily_transfer from './main/daily_transfer';
import deposit_check from './main/deposit_check';


const ProtectedView =()=>{

    return ( <>
    
    <Router  >
      <Switch>

      
    <Route exact path="/home" component={home} />
    <Route exact path="/customer/transfers" component={transfer} />
    <Route exact path="/customer/edit_profile" component={lookup_customer} />
    
    <Route exact path="/customer/daily_transfers" component={daily_transfer} />
    <Route exact path="/customer/previous" component={previous} />
    <Route exact path="/customer/exchange_rates" component={exchange} />
    <Route exact path="/customer/local" component={transfer_local} />
    <Route exact path="/customer/cheque" component={deposit_check} />
      </Switch>
      </Router>
      </>
    );
  
}
export default ProtectedView;
